
  import { defineComponent,reactive,ref, toRefs,nextTick} from 'vue'
  import HeaderVue from '@/components/shopping/Header.vue'
  import api from '@/assets/js/api'
  import storage from '@/assets/js/localStorage'
  import { useRoute,useRouter } from 'vue-router'
  import { Icon,Step, Steps,Toast} from 'vant'
  import PayFooter from '@/components/shopping/PayFooter.vue'
  import GoodsCardVue from '@/components/shopping/GoodsCard.vue'
    import useClipboard from 'vue-clipboard3'
  export default defineComponent({
    name: 'paydetail',
    props: {},
    components: {
        HeaderVue,
        [Icon.name]: Icon,
        [Step.name]: Step,
        [Steps.name]: Steps,
        [Toast.name]: Toast,
        PayFooter,
        GoodsCardVue
    },
    setup: () => {
        const isShowAllDetail= ref(false)
        const { toClipboard } = useClipboard()
        const route = useRoute()
        const showDetail = ref(null)
        const pageData = reactive({
            productInfo:{},
            orderInfo: {
                product:[{
                    goods:[{
                        image:'',
                        name: '',
                        buyQuantity: '',
                        goodsOrderTotalPriceYen: '',
                        goodsOrderTotalPriceRmb: ''
                    }]
                }]
            }
        })

           const getInfoHandle= async()=>{
            try{
            let data:any = await api.orderDetailApi({service: route.params.service,itemId: route.params.id})
                        data.data.product.map(item=>{
                            item.site_logo = item.siteLogo
                            item.siteName = item.site
                            item.goods = [{
                                image: item.product_img,
                                tag: item.tag,
                                name: item.product_name,
                                goodsOrderTotalPriceYen: item.unitPriceJpy,
                                goodsOrderTotalPriceRmb: item.unitPriceRmb,
                                buyQuantity: item.amount
                            }]
                        })
                        data.data.totalAmountRmb = storage.getOrderInfo()?.totalAmountRmb || 0
                        pageData.orderInfo = data.data
                        console.log(777,pageData.orderInfo)
                        nextTick(()=>{     
                            if( showDetail.value.offsetHeight < 180) isShowAllDetail.value = true
                        })
            }catch(err){
                if(err.length){
                    Toast('请获取正确的商品链接')
                }
            }
           
        }
        getInfoHandle()
      // 复制
      const copyHandle = async (text) => {
        console.log(text)
        try {
            await toClipboard(text)
            Toast('复制成功')
        } catch (e) {
            console.error(e)
        }
        }
        return{
             isShowAllDetail,
             copyHandle,
             showDetail,
             ...toRefs(pageData)
        }
    }
  })
